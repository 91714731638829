<template>
  <b-modal
    centered
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    body-class="d-flex w-100 align-items-center"
    @hidden="
      () => {
        $v.$reset();
        onRemoveMedia();
      }
    "
    id="medial-modal"
    size="lg"
    :title="$t(`sparkmembers.sales-page.info.form.media.type.${media}`)"
  >
    <b-form class="w-100 h-100" @submit.prevent="">
      <div
        class="d-flex w-50 h-100 flex-column align-items-center justify-content-center m-auto"
        v-if="!showEmbed && ['youtube', 'vimeo'].includes(media)"
      >
        <hs-group class="w-100">
          <label>{{ $t('sparkmembers.sales-page.info.form.media.link') }}</label>
          <hs-input
            type="url"
            v-model="form.s3_file_url"
            @blur="$v.form.s3_file_url.$touch()"
            :state="!$v.form.s3_file_url.$error ? null : false"
          >
            <template slot="feedback" v-if="$v.form.s3_file_url.$error">
              <hs-form-invalid-feedback :state="false" v-if="!$v.form.s3_file_url.required">
                {{ $t('validations.required') }}
              </hs-form-invalid-feedback>
              <hs-form-invalid-feedback :state="false" v-if="!$v.form.s3_file_url.url">
                {{ $t('validations.valid') }}
              </hs-form-invalid-feedback>
            </template>
            <template slot="feedback" v-if="invalidFormat">
              <hs-form-invalid-feedback :state="false">
                {{ $t('validations.valid') }}
              </hs-form-invalid-feedback>
            </template>
          </hs-input>
        </hs-group>
        <hs-button
          :variant="$v.form.s3_file_url.$invalid || loading.isSearching ? 'light' : 'primary'"
          :disabled="$v.form.s3_file_url.$invalid || loading.isSearching"
          type="button"
          @click="onSearch"
        >
          <b-spinner small type="grow" v-if="loading.isSearching" />
          {{ $t(`sparkmembers.sales-page.info.form.media.${loading.isSearching ? 'searching' : 'search'}`) }}
        </hs-button>
      </div>
      <template v-if="showEmbed && embedLink">
        <hs-group class="w-100">
          <label>{{ $t('sparkmembers.sales-page.info.form.media.name') }}</label>
          <hs-input
            type="text"
            v-model="form.title"
            :placeholder="$t('sparkmembers.sales-page.info.form.media.name-placeholder')"
            :disabled="loading.isUploading"
            @blur="$v.form.title.$touch()"
            :state="!$v.form.title.$error ? null : false"
          >
            <template slot="feedback" v-if="$v.form.title.$error">
              <hs-form-invalid-feedback :state="false" v-if="!$v.form.title.required">
                {{ $t('validations.required') }}
              </hs-form-invalid-feedback>
            </template>
          </hs-input>
        </hs-group>
        <b-embed allowfullscreen type="iframe" aspect="16by9" :src="embedLink" />
        <div class="w-100 text-right mt-3">
          <hs-button
            :variant="loading.isUploading ? 'light' : 'secondary'"
            :disabled="loading.isUploading"
            @click="onRemoveMedia"
            v-if="!form.id"
          >
            {{ $t('sparkmembers.sales-page.info.form.media.cancel') }}
          </hs-button>
          <hs-button
            :variant="$v.form.$invalid || loading.isUploading ? 'light' : 'primary'"
            :disabled="$v.form.$invalid || loading.isUploading"
            class="ml-2"
            @click="onSubmit"
          >
            <b-spinner small type="grow" v-if="loading.isUploading" />
            {{ $t(`sparkmembers.sales-page.info.form.media.${loading.isUploading ? 'loading' : 'load'}`) }}
          </hs-button>
        </div>
      </template>
    </b-form>
  </b-modal>
</template>

<script>
import { required, url } from 'vuelidate/lib/validators';
import { mediaService } from '@/services';
import VideoPreviewHelper from '@/sparkmembers/helpers/videoPreviewHelper';

export default {
  name: 'hsMedia',
  props: {
    media: String,
  },
  data() {
    return {
      loading: {
        isSearching: false,
        isUploading: false,
      },
      form: {
        title: null,
        s3_file_url: null,
        host_key: null,
        host: null,
        type: 'Video',
      },
      showEmbed: false,
      embedLink: null,
      invalidFormat: false,
    };
  },
  validations: {
    form: {
      s3_file_url: {
        required,
        url,
      },
      title: {
        required,
      },
    },
  },
  methods: {
    onSearch() {
      this.loading.isSearching = true;

      const regexURL = this.form.s3_file_url.match(
        /(http:\/\/|https:\/\/|)(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\\&\S+)?/
      );

      if (regexURL && regexURL[6]) {
        this.loading.isSearching = false;

        if (regexURL[3].indexOf('youtu') > -1 && this.media === 'youtube') {
          this.form.host = 'Youtube';
        }

        if (regexURL[3].indexOf('vimeo') > -1 && this.media === 'vimeo') {
          this.form.host = 'Vimeo';
        }

        this.form.host_key = regexURL[6];
        this.embedLink = VideoPreviewHelper.urlEmbed(this.media, regexURL[6]);
        this.showEmbed = true;
      } else {
        this.invalidFormat = true;
        this.loading.isSearching = false;
      }
    },
    async onSubmit() {
      this.loading.isUploading = true;
      mediaService
        .create(this.form)
        .then(media => {
          this.$emit('input', media.data);
          this.$v.$reset();
          this.$bvModal.hide('medial-modal');
        })
        .finally(() => {
          this.loading.isUploading = false;
        });
    },
    onRemoveMedia() {
      const { form } = this.$options.data.call(this);
      this.showEmbed = false;
      this.invalidFormat = false;
      this.form = form;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .modal .modal-body {
  min-height: 467px;
}
</style>
